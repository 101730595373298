import {useCallback, useEffect, useRef, useState} from 'preact/hooks';

import {useI18n} from '~/foundation/I18n/hooks';
import {useRootProvider} from '~/foundation/RootProvider/hooks';
import {useAuthorizeEventListener} from '~/hooks/useAuthorizeEventListener';
import {useAuthorizeUrl} from '~/hooks/useAuthorizeUrl';
import type {TextStyleDto} from '~/types/event';
import {postMessage} from '~/utils/postMessage';

type PrequalState = 'confirmed' | 'error' | 'loaded' | 'loading' | 'ready';

export type PrequalAmountOnReadyCallback = ({
  shopPayInstallmentsOnboarded,
  fontLoaded,
}: {
  shopPayInstallmentsOnboarded: boolean;
  fontLoaded: boolean;
}) => void;

export interface PrequalAmountProps {
  containerStyles?: TextStyleDto;
  onLoaded?: () => void;
  onReady?: PrequalAmountOnReadyCallback;
}

export function PrequalAmount({
  containerStyles,
  onLoaded,
  onReady,
}: PrequalAmountProps) {
  const {instanceId} = useRootProvider();
  const {authorizeUrl} = useAuthorizeUrl({
    analyticsContext: 'loginWithShopPrequal',
    analyticsTraceId: instanceId,
    disableSignUp: true,
    flow: 'prequal',
    flowVersion: 'prequalAmount',
    hideCopy: true,
    isCompactLayout: false,
    proxy: true,
    requireVerification: false,
  });
  const {translate} = useI18n();
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [fontLoaded, setFontLoaded] = useState<boolean>(false);
  const [onboarded, setOnboarded] = useState<boolean>(false);
  const [state, setState] = useState<PrequalState>('loading');

  const {destroy} = useAuthorizeEventListener({
    onCustomFlowSideEffect: ({
      fontAssetLoaded = false,
      shopPayInstallmentsOnboarded = false,
    }) => {
      setFontLoaded(fontAssetLoaded);
      setOnboarded(shopPayInstallmentsOnboarded);
      setState('confirmed');
    },
    onError: () => {
      setState('error');
      onReady?.({shopPayInstallmentsOnboarded: false, fontLoaded: false});
    },
    onLoaded: () => {
      if (state === 'loading') {
        setState('loaded');
      }
    },
    onResizeIframe: ({height, width}) => {
      if (iframeRef.current) {
        iframeRef.current.style.height = `${height}px`;
        iframeRef.current.style.width = `${width}px`;
      }
      if (state === 'confirmed') {
        setState('ready');
      }
    },
    source: iframeRef,
  });

  const syncStyles = useCallback(() => {
    if (containerStyles)
      postMessage({
        contentWindow: iframeRef?.current?.contentWindow,
        event: {
          type: 'setcomponentstyle',
          style: containerStyles,
        },
      });
  }, [containerStyles]);

  useEffect(() => {
    syncStyles();
  }, [containerStyles, syncStyles]);

  useEffect(() => {
    if (state === 'ready') {
      onReady?.({shopPayInstallmentsOnboarded: onboarded, fontLoaded});
    }
  }, [onReady, state, onboarded, fontLoaded]);

  useEffect(() => {
    if (state === 'loaded') {
      onLoaded?.();
      syncStyles();
    }
  }, [state, onLoaded, syncStyles]);

  useEffect(() => {
    const iframe = iframeRef.current;
    return () => {
      if (iframe) {
        destroy();
      }
    };
  }, [destroy]);

  return (
    <div
      data-testid="container"
      className="mr-0.5 flex flex-row content-center items-center gap-x-1"
    >
      <span data-testid="content">
        {translate('paymentTerms.prequalAmount.purchasingPower', {
          defaultValue: 'Your purchasing power is',
        })}
      </span>
      {/* eslint-disable-next-line @shopify/jsx-no-hardcoded-content */}
      <iframe
        className="m-0 inline-block size-0 border-0 p-0"
        data-testid="prequal-amount-iframe"
        ref={iframeRef}
        src={authorizeUrl}
        title="Shop Pay Installments pre-qualification amount"
      />
      {/* eslint-disable-next-line @shopify/jsx-no-hardcoded-content */}
      <span className="-ml-1 inline-block">.</span>
    </div>
  );
}
