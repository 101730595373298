import {useMemo} from 'preact/hooks';

import {useI18n} from '~/foundation/I18n/hooks';
import type {TranslateOptions} from '~/foundation/I18n/types';

import {useInstallments} from '../context/InstallmentsContext';
import {useLegalLinks} from '../hooks/useLegalLinks';
import {getSplitPayModalText} from '../utils/getModalText';
import {getSplitPayModalType} from '../utils/getModalType';

import {CreditCardIcon} from './CreditCardIcon';
import {FeesIcon} from './FeesIcon';
import {InstallmentsModalFooter} from './InstallmentsModalFooter';

export const SplitPayModalContent = () => {
  const context = useInstallments();
  const {lendersLink} = useLegalLinks();
  const {pricePerTerm, minPrice, maxPrice, fullPrice} = context;

  const {translate} = useI18n();

  const {subTitle, legalCopy} = useMemo(() => {
    const {subTitleKey, legalCopyKeys} = getSplitPayModalText({
      ...context,
      lendersLink,
      priceRange: {minPrice, maxPrice: maxPrice ?? ''},
    });

    const subTitle = translate(subTitleKey, {
      price: (
        <span
          className="font-bold"
          dangerouslySetInnerHTML={{__html: fullPrice}}
        />
      ),
      splitPayLoanRepayment: (
        <span
          className="font-bold"
          dangerouslySetInnerHTML={{__html: pricePerTerm}}
        />
      ),
      minPrice: <span dangerouslySetInnerHTML={{__html: minPrice}} />,
      maxPrice: <span dangerouslySetInnerHTML={{__html: maxPrice ?? ''}} />,
    });

    const legalCopy = legalCopyKeys
      .map((key) => {
        if (Array.isArray(key)) {
          const [translationKey, options]: [string, TranslateOptions] = key;
          return translate(translationKey, options);
        }
        return translate(key);
      })
      .filter(Boolean)
      .join(' ');

    return {subTitle, legalCopy};
  }, [
    context,
    fullPrice,
    lendersLink,
    maxPrice,
    minPrice,
    pricePerTerm,
    translate,
  ]);

  const listItems = useMemo(() => {
    const modalType = getSplitPayModalType(context);

    if (['adaptive', 'split_pay'].includes(modalType)) {
      return [
        {
          description: translate('paymentTerms.splitPayContents.interestFees'),
          icon: <FeesIcon />,
        },
        {
          description: translate(
            'paymentTerms.splitPayContents.interestCredit',
          ),
          icon: <CreditCardIcon />,
        },
      ];
    }

    return [
      {
        description: translate('paymentTerms.splitPayContents.noInterestFees'),
        icon: <FeesIcon />,
      },
      {
        description: translate(
          'paymentTerms.splitPayContents.noInterestCredit',
        ),
        icon: <CreditCardIcon />,
      },
    ];
  }, [context, translate]);

  return (
    <>
      <p
        className="mb-7 text-subtitle font-normal leading-snug"
        data-testid="split-pay-subtitle"
      >
        {subTitle}
      </p>
      <ul data-testid="split-pay-content" className="m-0 list-none p-0">
        {listItems.map((item, index) => (
          <li
            key={`split-pay-item-list-item=${index + 1}`}
            className="flex items-center pb-2"
          >
            <div className="box-content w-6 flex-none pr-3">{item.icon}</div>
            <span className="m-0">{item.description}</span>
          </li>
        ))}
      </ul>
      <p
        id="eligibility-approval"
        className="mb-5 mt-4 text-caption font-light tracking-wider text-grayscale-d0"
      >
        {legalCopy}
      </p>
      <InstallmentsModalFooter />
    </>
  );
};
